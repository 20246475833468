<template>
    <div>
        <button class="btn" v-if="progress">{{ progressValue }}%</button>
        <button class="btn" v-else @click="scrape">
            Scrape
        </button>
        <popup-shell v-if="isOpen" @close="isOpen = false">
            <div class="scrape-popup">
                <p>Nordvest hjørne</p>
                <input type="text" placeholder="Latitude 1" v-model="lat1" />
                <input type="text" placeholder="Longitude 1" v-model="lon1" />
                <p>Sørøst hjørne</p>
                <input type="text" placeholder="Latitude 2" v-model="lat2" />
                <input type="text" placeholder="Longitude 2" v-model="lon2" />
                <hr />
                <input type="text" placeholder="Fylke" v-model="county" />
                <input type="text" placeholder="Kommune" v-model="municipality" />
                <button class="btn" @click="doScrape">Scrape</button>
            </div>
        </popup-shell>
    </div>
</template>

<script>
export default {
    name: 'ScrapeButton',
    props: {
        areaId: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        lat1: '60.468973',
        lon1: '5.410541',
        lat2: '60.350250',
        lon2: '5.489108',
        county: 'Vestland',
        municipality: 'Bergen',
        isOpen: false,
    }),
    computed: {
        progress() {
            return this.$store.state.Areas.scrapers.find(scraper => scraper.areaId == this.areaId);
        },
        progressValue() {
            if (!this.progress) return 0;
            const progress = this.progress.completedFeatures + this.progress.errors.length;
            return Math.floor(100 * progress / this.progress.totalFeatures);
        },
    },
    methods: {
        scrape() {
            this.isOpen = true;
        },
        doScrape() {
            this.isOpen = false;
            this.$store.dispatch('Areas/scrapeArea', {
                areaId: this.areaId,
                lat1: this.lat1,
                lon1: this.lon1,
                lat2: this.lat2,
                lon2: this.lon2,
                county: this.county,
                municipality: this.municipality,
            });
        },
    },
}
</script>

<style lang="scss" scoped>
.btn {
    border-radius: 25px;
    padding: .75em 1.5em;
    font-size: 14px;
    letter-spacing: 0.15em;
    cursor: pointer;
    background: white;
    color: #424242;
    border: solid 1px #BFBFBF;

    &.disabled {
        opacity: 0.4;
    }
}

.scrape-popup {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    input {
        padding: 5px 10px;
    }
}
</style>