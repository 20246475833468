<template>
    <confirmation-box
        v-if="activatingArea"
        @close="activatingArea=null"
        @confirm="confirmActivateArea"
        :item="activatingArea.fullName"
    >Er du sikker på at du vil aktivere "{{ activatingArea.shortName }}"? Sonen vil bli synlig for brukere.</confirmation-box>
    <confirmation-box
        v-if="deactivatingArea"
        @close="deactivatingArea=null"
        @confirm="confirmDeactivateArea"
        :item="deactivatingArea.fullName"
    >Er du sikker på at du vil deaktivere "{{ deactivatingArea.shortName }}"? Sonen vil bli utilgjengelig for brukere.</confirmation-box>
    <confirmation-box
        v-if="resetingArea"
        @close="resetingArea=null"
        @confirm="confirmResetArea"
        :item="resetingArea.fullName"
    >Er du sikker på at du vil nullstille "{{ resetingArea.shortName }}"? Dette vil <b>permanent</b> fjerne alle skatter og vandringer - de vil ikke kunne hentes tilbake etterpå. Denne funksjonen er kun ment å brukes dersom det skjer en feil under scraping ved oppsett av en ny sone.</confirmation-box>
    <div class="areas-view">
        <header-shell>
            <template #title>Soner</template>
            <template #header-buttons>
                <!--header-button v-if="scrapeProgress !== null">{{ scrapeProgress }}%</header-button-->
                <!--header-button v-else @click="$store.dispatch('Pins/scrapeBergen')">Scrape for Bergen</header-button-->
                <header-button @click="editingArea = true">+ Ny sone</header-button>
            </template>
        </header-shell>
        <div class="areas">
            <item-card v-for="area in areas" :delete-button="false">
                <span>
                    {{ area.currentTreasures }} / {{ area.maxTreasures }} kulturskatter<br />
                    {{ area.currentAdventures }} / {{ area.maxAdventures }} vandringer
                </span>
                <template #title>{{ area.shortName }}</template>
                <template #buttons>
                    <button class="btn" @click="editArea(area)">Rediger</button>
                    <button class="btn" @click="goToAccesses(area)">Tilganger</button>
                    <button class="btn" @click="deactivatingArea=area" v-if="area.active">Deaktiver</button>
                    <button class="btn" @click="activatingArea=area" v-else>Aktiver</button>
                    <scrape-button :area-id="area.id" />
                    <button class="btn" @click="resetingArea=area">Nullstill</button>
                </template>
            </item-card>
        </div>
    </div>
    <edit-area v-if="editingArea" @close="editingArea = false" :area="editingArea === true ? null : editingArea"></edit-area>
</template>

<script>
import { defineComponent } from 'vue'
import ConfirmationBox from '@/components/ConfirmationBox.vue'
import EditArea from './AreasView/EditArea.vue'
import ScrapeButton from './AreasView/ScrapeButton.vue';

export default defineComponent({
    name: 'AreasView',
    components: {
        ConfirmationBox,
        EditArea,
        ScrapeButton,
    },
    data: () => ({
        activatingArea: null,
        deactivatingArea: null,
        editingArea: false,
        resetingArea: null,
    }),
    computed: {
        areas() {
            return this.$store.state.Areas.areas.map(a => a.area);
        },
        scrapeProgress() {
            /*const task = this.$store.state.Tasks.tasks.filter(task => task.name == 'admin-kmsscrapebergen')[0];
            if (!task) return null;
            const percent = 100 * task.currentProgress / task.totalProgress;
            return Math.floor(percent);*/
            return null;
        },
    },
    methods: {
        editArea(area) {
            this.editingArea = area;
        },
        async goToAccesses(area) {
            await this.$store.dispatch('Areas/selectArea', area.id);
            this.$router.push({ name: 'tilganger' });
        },
        async confirmActivateArea() {
            await this.$store.dispatch('Areas/activate', this.activatingArea);
            this.activatingArea = null;
        },
        async confirmDeactivateArea() {
            await this.$store.dispatch('Areas/deactivate', this.deactivatingArea);
            this.deactivatingArea = null;
        },
        async confirmResetArea() {
            await this.$store.dispatch('Areas/deleteAllPins', this.resetingArea.id);
            this.resetingArea = null;
        },
    },
});
</script>

<style lang="scss" scoped>
.header {
    display: flex;
}

.areas {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn {
    border-radius: 25px;
    padding: .75em 1.5em;
    font-size: 14px;
    letter-spacing: 0.15em;
    cursor: pointer;
    background: white;
    color: #424242;
    border: solid 1px #BFBFBF;

    &.disabled {
        opacity: 0.4;
    }
}
</style>