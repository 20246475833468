import { Module } from 'vuex'
import { query, collection, getFirestore, where, orderBy, limit } from 'firebase/firestore'
import Area from '../../types/Area'
import Municipality from '../../types/Municipality'
//import AreaStats from '../../types/AreaStats'
import { AuthState } from './Auth';
import Firestore, { Listener, UpdateType } from '@/util/FirestoreHelper'
import callFirebase from '@/util/callFirebase'
import ScrapeProgress from '@/types/ScrapeProgress';

type Role = 'admin'|'editor'|'viewer';

export interface AreaInfo {
    area: Area,
    role: Role,
}

export interface AreasState {
    areas: AreaInfo[]
    selectedArea: AreaInfo|null
    //stats: AreaStats|null
    isSystemAdmin: boolean
    municipalities: Municipality[]
    scrapers: ScrapeProgress[]
}

let areaListener: Listener<Area|null>|null = null;
let scrapeListener: Listener<ScrapeProgress[]>|null = null;

const areasModule: Module<AreasState, { Auth: AuthState }> = {
    namespaced: true,
    state: {
        areas: [],
        selectedArea: null,
        //stats: null,
        isSystemAdmin: false,
        municipalities: [],
        scrapers: [],
    },
    mutations: {
        saveAreas(state, areas: AreaInfo[]) {
            state.areas = areas;
        },
        setSystemAdmin(state, isSystemAdmin) {
            state.isSystemAdmin = isSystemAdmin;
        },
        setSelectedArea(state, area: AreaInfo|null) {
            if (area) localStorage.setItem('selectedArea', area.area.id);
            else localStorage.removeItem('selectedArea');
            state.selectedArea = area;
        },
        setAreaStats(state, stats: null) {
            //state.stats = stats;
        },
        addArea(state, area: Area) {
            state.areas.unshift({ area, role: 'admin' });
        },
        updateArea(state, area: Area) {
            state.areas = state.areas.map(a => a.area.id == area.id ? { area, role: a.role } : a);
            if (state.selectedArea && state.selectedArea.area.id == area.id) state.selectedArea = { area, role: state.selectedArea.role };
        },
        saveMunicipalities(state, municipalities: Municipality[]) {
            state.municipalities = municipalities;
        },
        saveScrapers(state, scrapers: ScrapeProgress[]) {
            state.scrapers = scrapers;
        },
    },
    actions: {
        async onSignIn({ commit, dispatch, rootState }) {
            const user = rootState.Auth.user!;

            const db = getFirestore();

            let areas: Area[];
            if (user.isAdmin) {
                // Load all areas
                areas = await Firestore.get(query(collection(db, 'Area')));
                commit('setSystemAdmin', true);
                scrapeListener = Firestore.listen<ScrapeProgress>(query(collection(getFirestore(), 'Scrape'), where('completed', '==', false)), scrapers => commit('saveScrapers', scrapers));
            } else {
                // Load areas where the user has a role
                areas = await Firestore.get(Object.keys(user.roles).map(key => `Area/${key}`)) as Area[];
                commit('setSystemAdmin', false);
            }
            commit('saveAreas', areas.map(area => ({ area, role: user.isAdmin ? 'admin' : user.roles[area.id] })));

            const municipalities = await Firestore.get(query(collection(db, 'Municipality')));
            commit('saveMunicipalities', municipalities);

            if (areas.length > 0) {
                let selectedId = localStorage.getItem('selectedArea');
                let selectedArea: Area|undefined = areas.filter(area => area.id == selectedId)[0];
                await dispatch('selectArea', (selectedArea ?? areas[0]).id);
            }
        },
        onSignOut({ dispatch }) {
            dispatch('selectArea'); // Un-select the selected area
        },
        async selectArea({ dispatch, commit, state, rootState }, areaId?: string) {
            areaListener?.unsubscribe();
            areaListener = null;

            const user = rootState.Auth.user!;
            if (user && areaId && user.selectedArea != areaId) await Firestore.update(user, {
                selectedArea: areaId,
            });

            if (!areaId) {
                commit('setSelectedArea', null);
                commit('setAreaStats', null);
                dispatch('moduleEvent', { event: 'areaChanged', data: null }, { root: true });
                return;
            }

            const [ areaInfo ] = state.areas.filter(info => info.area.id == areaId);
            commit('setSelectedArea', areaInfo);
            commit('setAreaStats', null); // TODO
            dispatch('moduleEvent', { event: 'areaChanged', data: areaInfo }, { root: true });

            areaListener = Firestore.listen(`Area/${areaId}`, area => commit('updateArea', area));
        },
        async sendAdminNotification({}, { title, body, areaIds }: { title: string, body: string, areaIds: string[]}) {
            await callFirebase('admin-notify', { title, body, areas: areaIds });
        },
        async reloadArea({ commit }, areaId: string) {
            const area = await Firestore.get<Area>(`Area/${areaId}`);
            commit('updateArea', area);
            return area;
        },
        async randomArea() {
            // This is used for the simulated invite function, which is used to test the invite page without making an
            // actual invite. It's not meant to be used in proper production behaviour.
            const areas = await Firestore.get<Area>(query(collection(getFirestore(), 'Area'), where('active', '==', true), limit(1)));
            return areas[0];
        },
        async createArea({ commit }, data: UpdateType<Area>) {
            data.active = false;
            data.currentAdventures = 0;
            data.currentTreasures = 0;
            const area = await Firestore.add<Area>('Area', data);
            commit('addArea', area);
            return area;
        },
        async updateArea({ commit, state }, data: Partial<Area>) {
            const area = state.areas.filter(a => a.area.id == data.id)[0].area
            await Firestore.update(area, data);
            commit('updateArea', Object.assign({}, area, data));
            return area;
        },
        async activate({ dispatch }, area: Area) {
            await Firestore.update(area, { active: true });
            await dispatch('reloadArea', area.id);
        },
        async deactivate({ dispatch }, area: Area) {
            await Firestore.update(area, { active: false });
            await dispatch('reloadArea', area.id);
        },
        async scrapeArea(_, { areaId, county, municipality, lat1, lon1, lat2, lon2 }: { areaId: string, county: string, municipality: string, lat1: number, lon1: number, lat2: number, lon2: number }) {
            callFirebase('admin-scrape', {
                bounds: [lat1, lon1, lat2, lon2],
                areaId,
                county,
                municipality,
            });
        },
        async deleteAllPins(_, areaId: string) {
            const docs = await Firestore.getForQuery(query(collection(getFirestore(), 'Pin'), where('area', '==', areaId)));
            await Promise.all(docs.map(doc => Firestore.delete(doc)));
            //console.log(docs);
        },
    },
    getters: {
        hasViewerAccess(state) {
            if (state.isSystemAdmin) return true;
            if (!state.selectedArea) return false;
            return state.selectedArea.role == 'admin' || state.selectedArea.role == 'editor' || state.selectedArea.role == 'viewer';
        },
        hasEditorAccess(state) {
            if (state.isSystemAdmin) return true;
            if (!state.selectedArea) return false;
            return state.selectedArea.role == 'admin' || state.selectedArea.role == 'editor';
        },
        hasAdminAccess(state) {
            if (state.isSystemAdmin) return true;
            if (!state.selectedArea) return false;
            return state.selectedArea.role == 'admin';
        },
        hasSystemAdminAccess(state) {
            return state.isSystemAdmin;
        },
    },
}

export default areasModule;