import { Module } from 'vuex'
import PinCategory from '@/types/PinCategory'
import Firestore from '@/util/FirestoreHelper'
import { getFirestore, collection } from 'firebase/firestore'

export interface CategoriesState {
    categories: PinCategory[]
    byId: { [id: string]: PinCategory }
}

const assetUrls: { [key: string]: string } = {
    attraction: '/pins/severdighet.svg',
    building: '/pins/bygninger.svg',
    history: '/pins/historie.svg',
    museum: '/pins/museum.svg',
    religion: '/pins/religion.svg',
    walk: '/pins/tur.svg',
    adventure: '/pins/vandringer.svg',
    statue: '/pins/statue.svg',
}

const pinCategoriesModule: Module<CategoriesState, void> = {
    namespaced: true,
    state: {
        categories: [],
        byId: {},
    },
    mutations: {
        savePinCategories(state, categories: PinCategory[]) {
            state.categories = categories;
            state.byId = {};
            for (let category of categories) state.byId[category.id] = category;
        },
    },
    actions: {
        async init({ commit }) {
            const categories = await Firestore.get<PinCategory>(collection(getFirestore(), 'PinCategory'));
            commit('savePinCategories', categories);
        },
    },
    getters: {
        //assetUrl: state => (category: PinCategory) => assetUrls[category.iconName] ?? assetUrls.attraction,
        assetUrl: state => (category?: PinCategory) => {
            if (!category) return assetUrls.attraction;
            return assetUrls[category.iconName] ?? assetUrls.attraction;
        },
    },
}

export default pinCategoriesModule;