<template>
    <nav>
      <router-link to="/" v-if="user"><the-logo /></router-link>
      <the-logo v-else />
      <div class="menu-text">
        <router-link to="/dashboard" v-if="$store.getters['Areas/hasSystemAdminAccess']">Dashboard</router-link>
        <router-link to="/" v-if="$store.getters['Areas/hasEditorAccess']">Skatter</router-link>
        <router-link to="/vandringer" v-if="$store.getters['Areas/hasEditorAccess']">Vandringer</router-link>
        <!--router-link to="/premier" v-if="$store.getters['Areas/hasViewerAccess']">Premier</router-link-->
        <router-link to="/tilganger" v-if="$store.getters['Areas/hasAdminAccess']">Tilganger</router-link>
        <router-link to="/varsling" v-if="$store.getters['Areas/hasSystemAdminAccess']">Varsling</router-link>
        <router-link to="/skjemaer" v-if="$store.getters['Areas/hasSystemAdminAccess']">
            <div class="forms-link-wrapper">
                <div class="forms-unread-badge" v-if="unreadFormResponses > 0"><span>{{ unreadFormResponses }}</span></div>
                Skjemaer
            </div>
        </router-link>
        <router-link to="/soner" v-if="$store.getters['Areas/hasSystemAdminAccess']">Soner</router-link>
        <div class="spacer"></div>
        <Dropdown :values="areas" :modelValue="selectedArea" @update:modelValue="selectArea" v-if="areas.length > 1">
            <template v-slot="{ value }">{{ value.shortName }}</template>
        </Dropdown>
        <a v-if="user" class="log-out" @click="$store.dispatch('Auth/logOut')">Logg ut</a>
      </div>
      <div class="hamburger-menu" @click="menuIsOpen = !menuIsOpen">
        <img src="../assets/hamburger_menu.png" alt="" />
      </div>
      <div class="popup-menu" v-if="menuIsOpen">
        <router-link to="/dashboard" @click="menuIsOpen = false" v-if="$store.getters['Areas/hasSystemAdminAccess']">Dashboard</router-link>
        <router-link to="/" @click="menuIsOpen = false" v-if="$store.getters['Areas/hasEditorAccess']">Skatter</router-link>
        <router-link to="/vandringer" @click="menuIsOpen = false" v-if="$store.getters['Areas/hasEditorAccess']">Vandringer</router-link>
        <!--router-link to="/premier" @click="menuIsOpen = false" v-if="$store.getters['Areas/hasViewerAccess']">Premier</router-link-->
        <router-link to="/tilganger" @click="menuIsOpen = false" v-if="$store.getters['Areas/hasAdminAccess']">Tilganger</router-link>
        <router-link to="/varsling" @click="menuIsOpen = false" v-if="$store.getters['Areas/hasSystemAdminAccess']">Varsling</router-link>
        <router-link to="/skjemaer" @click="menuIsOpen = false" v-if="$store.getters['Areas/hasSystemAdminAccess']">Skjemaer</router-link>
        <router-link to="/soner" @click="menuIsOpen = false" v-if="$store.getters['Areas/hasSystemAdminAccess']">Soner</router-link>
        <a v-if="user" class="log-out" @click="$store.dispatch('Auth/logOut')">Logg ut</a>
      </div>
    </nav>
</template>

<script>
import TheLogo from '../assets/TheLogo.vue'
import Dropdown from '@/components/Dropdown.vue'

export default {
    components: {
        TheLogo,
        Dropdown,
    },
    data() {
        return {
            menuIsOpen: false,
        }
    },
    computed: {
        user() {
            return this.$store.state.Auth.user;
        },
        areas() {
            return this.$store.state.Areas.areas.map(area => area.area);
        },
        selectedArea() {
            return this.$store.state.Areas.selectedArea?.area;
        },
        unreadFormResponses() {
            return this.$store.state.Forms.totalUnreadCount;
        },
    },
    methods: {
        selectArea(area) {
            this.$store.dispatch('Areas/selectArea', area.id);
        },
    },
}
</script>


<style lang="scss" scoped>
@import '@/assets/vars.scss';

nav {
    padding: 24px 0 24px 24px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    background: #6d6c97;
    //box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    .menu-text {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 10px;

        @media (max-width: $tablet-size) {
            display: none;
        }
    }
    .popup-menu {
        background: #6d6c97;
        padding: 30px;
        position: absolute;
        display: flex;
        right: 20px;
        top: 100px;
        flex-direction: column;
        z-index: 100;

        a {
        padding-bottom: 15px;
        margin-right: 0;
        padding-left: 0;
        }

        .log-out {
        position: static;
        right: 0;
        }

    }
    .hamburger-menu {
        width: 60px;
        height: 60px;
        position: absolute;
        right: 20px;

        img {
        height: 100%;
        width: 100%;
        }
        
        @media (min-width: $tablet-size) {
            display: none;
        }
    }

    a {
        font-weight: 600;
        color: white;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.05em;
        margin-right: 40px;
        text-decoration: none;
        text-transform: capitalize;

        &.router-link-active {
            text-decoration: underline;
        }
    }
    .spacer {
        flex-grow: 1;
    }
    .log-out {
        padding-left: 1.5rem;
    }
    a:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .forms-link-wrapper {
        position: relative;

        .forms-unread-badge {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -15px;
            right: -20px;
            background: red;
            border-radius: 100%;
            font-size: 0.75em;
            width: 20px;
            height: 20px;
        }
    }
}
.custom-select {
    display: grid;
    grid-template-areas: "select";
    border: 1px solid gray;
    border-radius: 5px;
    padding: 6px 20px;
    font-size: 1.25rem;
    cursor: pointer;
    background-color: #fff;
    align-items: center;

    select {
        // A reset of styles, including removing the default dropdown arrow
        appearance: none;
        // Additional resets for further consistency
        background-color: transparent;
        border: none;
        padding: 0 1.5em 0 0;
        margin: 0;
        width: 100%;
        font-family: inherit;
        font-size: 16px;
        cursor: inherit;
        line-height: inherit;
        outline: none;
        grid-area: select;
    }
}
.custom-select::after {
    content: "";
    grid-area: select;
    width: 12px;
    height: 7px;
    background-color: darkslategray;
    clip-path: polygon(25% 0, 50% 55%, 75% 0, 100% 0, 50% 100%, 0 0);
    justify-self: end;
}

</style>